<template>
  <v-card elevation="0" max-width="1010" rounded="xl" class="mx-auto pa-sm-8 pa-4 pass-reset width100">
    <v-btn class="btn-back px-2" text @click="back">
      <v-icon small color="gray" class="pt-1">mdi-chevron-left mdi-24px</v-icon>
      <span class="gray--text text-body-1">{{ $t('btn.back') }}</span>
    </v-btn>
    <v-img height="105" contain class="mx-auto mb-12" src="@/assets/icon/logo.svg" alt="logo"></v-img>
    <div class="f30 arabic-black text-center mb-2 black--text">{{ $t('passReset.title') }}</div>
    <div style="max-width: 420px" class="gray--text text-center mb-8 mx-auto">{{ $t('passReset.description') }}</div>
    <div style="max-width: 380px" class="mx-auto mb-9">
      <v-form @submit.prevent="action" ref="form" lazy-validation>
        <div class="black--text">{{ $t('input.email') }}</div>
        <v-text-field v-model="email" :placeholder="$t('input.email')" :error-messages="emailErrors" outlined required color="secondary">
        </v-text-field>
        <v-btn height="64" depressed type="submit" block large class="primary radius10 mt-6">
          <span class="text-body-1 font-weight-bold">{{ $t('btn.continue') }}</span>
        </v-btn>
      </v-form>
    </div>
  </v-card>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      email: '',
      error: [],
    };
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    async action() {
      this.error = [];
      const data = new Object();
      data.email = this.email;
      await this.$store
        .dispatch('passResetRequest', data)
        .then(() => {
          this.$router.push('/password-reset-sent');
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
  },
  computed: {
    emailErrors() {
      const errors = [];
      this.error.find((item) => item == 'email__required') && errors.push(this.$t('inputError.emailRequired'));
      this.error.find((item) => item == 'email__invalid') && errors.push(this.$t('inputError.emailInvalid'));
      this.error.find((item) => item == 'email__already_used') && errors.push(this.$t('inputError.emailUsed'));
      this.error.find((item) => item == 'bad_credentials') && errors.push(this.$t('inputError.badCredLogin'));
      return errors;
    },
  },
  destroyed() {
    sessionStorage.removeItem('sid');
  },
};
</script>

<style lang="scss">
.pass-reset {
  position: relative;
  .btn-back {
    z-index: 1;
    position: absolute;
    top: 50px;
    left: 50px;
  }
}
</style>
